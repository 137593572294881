<script lang="ts">
import { defineComponent } from 'vue';
import algoliasearch from 'algoliasearch/lite';
import clickOutside from '../directives/clickOutside';

import {
  AisHits,
  AisInstantSearch,
  AisSearchBox,
  AisStateResults,
  AisStats,
} from 'vue-instantsearch/vue3/es';

export default defineComponent({
  components: {
    AisHits,
    AisInstantSearch,
    AisSearchBox,
    AisStateResults,
    AisStats,
  },
  directives: {
    clickOutside,
  },
  props: {
    searchApiKey: {
      type: String,
      required: true,
    },
    appId: {
      type: String,
      required: true,
    },
    environment: {
      type: String,
      required: true,
    },
    isDesktop: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isEnabled: false,
      searchClient: algoliasearch(this.appId, this.searchApiKey),
      searchQuery: '',
    };
  },
  computed: {
    indexName() {
      return `${this.environment}_headerSearch`;
    },
  },
  watch: {
    /**
     * Open / close search if device width/orientation changes
     */
    isDesktop: {
      immediate: true,
      handler(isDesktop: boolean) {
        this.isEnabled = !isDesktop;
      },
    },
  },
  methods: {
    clickOutside() {
      if (this.isDesktop) {
        this.isEnabled = false;
      }
    },
    focusSearchInput() {
      const { searchBox } = this.$refs;
      const { searchInput } = searchBox.$refs;
      const { input } = searchInput.$refs as { input: HTMLInputElement };
      // Focus the search input (using autofocus is only reliable on page load)
      input.focus();
    },
  },
});
</script>

<template>
  <div v-click-outside.stop="clickOutside" class="c-header-search relative lg:flex lg:items-center">
    <button class="hidden lg:block" title="Open search bar" @click="isEnabled = true">
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="Search icon"
      >
        <g>
          <circle
            cx="8.60864"
            cy="8.93118"
            r="8.10487"
            transform="rotate(-0.0250705 8.60864 8.93118)"
            stroke="#101820"
          />
          <line x1="14.2891" y1="14.0332" x2="18.3867" y2="18.1307" stroke="#101820" />
        </g>
      </svg>
    </button>
    <Transition @after-enter="focusSearchInput">
      <div
        v-if="isEnabled"
        class="z-50 rounded-2xl text-evolve-charcoal lg:absolute lg:-right-3 lg:-top-[0.688rem] [&.v-enter-active]:transition-opacity [&.v-enter-from]:opacity-0 [&.v-leave-active]:transition-opacity [&.v-leave-to]:opacity-0"
      >
        <ais-instant-search
          class="relative rounded-3xl bg-white"
          :search-client="searchClient"
          :index-name="indexName"
        >
          <ais-search-box
            ref="searchBox"
            v-model="searchQuery"
            show-loading-indicator
            placeholder="Search..."
            :class-names="{
              'ais-SearchBox-form': 'relative',
              'ais-SearchBox-input': `bg-evolve-stone-mist rounded-3xl pl-4 pr-16 py-2 focus:outline-none lg:px-4 w-full lg:w-80`,
              'ais-SearchBox-loadingIndicator': 'hidden',
              'ais-SearchBox-reset': 'hidden',
              'ais-SearchBox-submit': '-translate-y-1/2 absolute right-3 top-1/2',
            }"
            @keydown.esc="isEnabled = false"
          >
            <template #submit-icon>
              <svg
                class="mx-auto"
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                aria-label="Search icon"
                @click="focusSearchInput"
              >
                <g stroke="#101820">
                  <circle
                    cx="8.60864"
                    cy="8.93118"
                    r="8.10487"
                    transform="rotate(-0.0250705 8.60864 8.93118)"
                  />
                  <line x1="14.2891" y1="14.0332" x2="18.3867" y2="18.1307" />
                </g>
              </svg>
            </template>
          </ais-search-box>
          <ais-stats>
            <template #default="{ nbHits, query }">
              <section
                v-if="nbHits > 0 && query"
                class="md:left-unset absolute left-0 right-0 top-0 mx-auto mt-12 w-[19.438rem] rounded-3xl bg-white p-5 shadow-xl md:mt-20"
              >
                <div class="flex items-center justify-between">
                  <h2 class="pl-[5px] font-raleway text-2xl font-bold text-evolve-charcoal">
                    Results
                  </h2>
                  <svg
                    @click="searchQuery = ''"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-8 w-8 cursor-pointer"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                  </svg>
                </div>
                <div class="c-search-results max-h-[32.25rem] overflow-y-auto rounded-3xl">
                  <ais-state-results>
                    <template #default="{ results: { hits /* , query */ } }">
                      <ais-hits v-if="hits.length > 0 /*  && query */">
                        <template #default="{ items /* , sendEvent, query */ }">
                          <ul v-if="items.length > 0" class="flex flex-col justify-start">
                            <li v-for="item in items" :key="item.objectID">
                              <a
                                :href="item.url"
                                class="block pl-[5px] pt-7 text-base font-medium text-evolve-charcoal hover:underline"
                              >
                                {{ item.title }}
                              </a>
                            </li>
                          </ul>
                          <div v-else>No results</div>
                        </template>
                      </ais-hits>
                      <div v-else-if="hits.length === 0 && query">
                        'No products have been found for {{ query }}.
                      </div>
                      <div v-else></div>
                    </template>
                  </ais-state-results>
                </div>
              </section>
              <section
                v-else-if="nbHits === 0 && query.length > 0"
                class="md:left-unset absolute left-0 right-0 top-0 mx-auto mt-12 w-[19.438rem] overflow-scroll rounded-3xl bg-white p-6 shadow-xl"
              >
                <p class="block text-base font-medium text-evolve-charcoal">
                  No results have been found for {{ query }}.
                </p>
              </section>
              <!-- Blank for the else as we want no output -->
              <div v-else></div>
            </template>
          </ais-stats>
        </ais-instant-search>
      </div>
    </Transition>
  </div>
</template>
